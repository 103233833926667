<template>
    <div class="d-flex flex-column fill-height">
        <v-col class="pa-0 ma-0 mb-auto">
            <v-row no-gutters>
                <v-col class="col-9-5 d-flex flex-column pt-3">
                    <div>
                        <span class="header-title">{{ Office__title  }}</span>
                        <span class="header-subtitle font-weight-bold" style="vertical-align: top" v-if="Office__companyno != ''">{{ '(' + Office__companyno + ')' }}</span>
                    </div>
                    <span class="header-subtitle pt-1 pb-0">{{ Office__address1  }}</span>
                    <span class="header-subtitle pt-1 pb-0">{{ [Office__address2, Office__postcode, Office__city, Office__state, Office__country].filter(word => word != null && word != '').join(', ')  }}</span>
                    <span class="header-subtitle pt-1 pb-0" v-if="Office__invoicetel != null">{{ $t('message.tel') + ': ' + Office__invoicetel}}</span>
                    <span class="header-subtitle pt-1 pb-0">{{ $t('message.email') + ': ' + Office__invoiceemail + ' ' + $t('message.website') + ': ' + Office__website }}</span>
                </v-col>
                <v-col class="col-2-5 text-right">
                    <img
                        :src="appLogo"
                        class="mb-3 mr-3"
                        width="130px"
                    />
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" class="text-center text-uppercase border-top-2 border-bottom-2 pa-0 font-weight-bold document-caption">{{ $t('message.debitNote') }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="7">
                    <v-row no-gutters>
                        <v-col cols="3" class="font-weight-bold">{{ $t('message.messrs') }}:</v-col>
                        <v-col cols="8" class="d-flex flex-column" v-if="Contract__commission_term_against_id == 0">
                            <span>{{ Supplier__title }}</span>
                            <span>{{ Supplier__address1 }}</span>
                            <span>{{ Supplier__address2 }}</span>
                            <span>{{ Supplier__address3 }}</span>
                            <span>{{ Supplier__city + ' ' + Supplier__postcode}}</span>
                            <span>{{ Supplier__state }}</span>
                            <span>{{ Supplier__country }}</span>
                            <span v-html="getTaxId()"></span>
                        </v-col>
                        <v-col cols="8" class="d-flex flex-column" v-if="Contract__commission_term_against_id == 1">
                            <span>{{ Customer__title }}</span>
                            <span>{{ Customer__address1 }}</span>
                            <span>{{ Customer__address2 }}</span>
                            <span>{{ Customer__address3 }}</span>
                            <span>{{ Customer__city + ' ' + Customer__postcode}}</span>
                            <span>{{ Customer__state }}</span>
                            <span>{{ Customer__country }}</span>
                            <span v-html="getTaxId()"></span>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3" class="font-weight-bold">{{ $t('message.attn') }}:</v-col>
                        <v-col cols="8" v-if="Contract__commission_term_against_id == 1">{{ Contact__prefix ? Contact__prefix + ' ' : '' }}{{ Contact__name ? Contact__name : $t('message.na').toUpperCase() }}</v-col>
                        <v-col cols="8" v-if="Contract__commission_term_against_id == 0">{{ SupplierContact__prefix ? Contact__prefix + ' ' : '' }}{{ SupplierContact__name ? SupplierContact__name : $t('message.na').toUpperCase() }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3" class="font-weight-bold">{{ $t('message.tel') }}:</v-col>
                        <v-col cols="8" v-if="Contract__commission_term_against_id == 1">{{ Contact__tel ? Contact__tel : $t('message.na').toUpperCase() }}</v-col>
                        <v-col cols="8" v-if="Contract__commission_term_against_id == 0">{{ SupplierContact__tel ? SupplierContact__tel : $t('message.na').toUpperCase() }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3" class="font-weight-bold">{{ $t('message.hp') }}:</v-col>
                        <v-col cols="8" v-if="Contract__commission_term_against_id == 1">{{ Contact__hp ? Contact__hp : $t('message.na').toUpperCase() }}</v-col>
                        <v-col cols="8" v-if="Contract__commission_term_against_id == 0">{{ SupplierContact__hp ? SupplierContact__hp : $t('message.na').toUpperCase() }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3" class="font-weight-bold">{{ $t('message.email') }}:</v-col>
                        <v-col cols="8" v-if="Contract__commission_term_against_id == 1">{{ Contact__email ? Contact__email : $t('message.na').toUpperCase() }}</v-col>
                        <v-col cols="8" v-if="Contract__commission_term_against_id == 0">{{ SupplierContact__email ? SupplierContact__email : $t('message.na').toUpperCase() }}</v-col>
                    </v-row>
                </v-col>
                <v-col cols="5">
                    <v-row no-gutters>
                        <v-col cols="6" class="font-weight-bold pl-4">{{ $t('message.date') }}:</v-col>
                        <v-col cols="6">{{ DebitNote__dndate ? formatDate(DebitNote__dndate) : '' }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="font-weight-bold pl-4">{{ $t('message.invoiceNo') }}:</v-col>
                        <v-col cols="6">{{ DebitNote__title }}</v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-simple-table
                class="specification-table"
                dense
            >
                <template v-slot:default>
                    <tr class="table-header">
                        <th class="px-0 font-weight-bold border-bottom-1 border-top-1 text-left justify-start">{{ $t('message.description') }}</th>
                        <th class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right width-15-pct" v-if="taxMode">{{ $t('message.amount') + " (" + officeCurrency + ") @" + (DebitNote__gstrmrate ? DebitNote__gstrmrate : 0) }}</th>
                        <th class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right width-15-pct">{{ $t('message.amount') + " (" + currency + ")" }}</th>
                    </tr>
                    <tr class="table-row">
                        <td colspan="3" class="font-weight-bold px-0">{{ $t('message.professionalFeeForServices') }}:</td>
                    </tr>
                    <tr class="table-row">
                        <td :colspan="taxMode ? 3 : 2" class="px-0 d-flex flex-row">
                            <span class="width-25-pct">{{ $t('message.contract') }}:</span>
                            <span>{{ Contract__version != null ? [Contract__title, Contract__revision_no , Contract__partial_no].filter(Boolean).join('-') : Contract__title }}</span>
                        </td>
                    </tr>
                    <tr class="table-row">
                        <td :colspan="taxMode ? 3 : 2" class="px-0 d-flex flex-row">
                            <span class="width-25-pct">{{ $t('message.invoice') }}:</span>
                            <span>{{ DebitNote__invno }}</span>
                        </td>
                    </tr>
                    <tr class="table-row">
                        <td :colspan="taxMode ? 3 : 2" class="px-0 d-flex flex-row">
                            <span class="width-25-pct">{{ $t('message.shippedOn') }}:</span>
                            <span>{{ formatDate(Contract__etddate) }}</span>
                        </td>
                    </tr>
                    <tr class="table-row" v-if="Contract__commission_term_against_id == 0">
                        <td :colspan="taxMode ? 3 : 2" class="px-0 d-flex flex-row">
                            <span class="width-25-pct">{{ $t('message.customer') }}:</span>
                            <span>{{ Customer__title }}</span>
                        </td>
                    </tr>
                    <tr class="table-row" v-if="Contract__commission_term_against_id == 1">
                        <td :colspan="taxMode ? 3 : 2" class="px-0 d-flex flex-row">
                            <span class="width-25-pct">{{ $t('message.supplier') }}:</span>
                            <span>{{ Supplier__title }}</span>
                        </td>
                    </tr>
                    <tr class="table-row">
                        <td :colspan="taxMode ? 3 : 2" class="px-0 d-flex flex-row">
                            <span class="width-25-pct">{{ valueTypes.find( v => v.value == DebitNote__valuetype ).text + " " + $t('message.value') }}:</span>
                            <span>{{ currency + " " + formatThisNumber(DebitNote__dnvalue,currencyFormatPrecision) }}</span>
                        </td>
                    </tr>
                    <tr class="table-row" v-if="[0,1,2].includes(Contract__commission_term_id)">
                        <td class="px-0 d-flex flex-row">
                            <span class="width-25-pct">{{ $t('message.fee') + " (%)" }}:</span>
                            <span v-if="Contract__commission_term_id == 0">{{ formatThisNumber(DebitNote__cfrciffee,'0,0.00') }}</span>
                            <span v-if="Contract__commission_term_id == 1">{{ formatThisNumber(DebitNote__fobfee,'0,0.00') }}</span>
                            <span v-if="Contract__commission_term_id == 2">{{ formatThisNumber(DebitNote__cfrciffee,'0,0.00') }}</span>
                        </td>
                        <td class="px-0 text-right" v-if="taxMode && [21].includes(Contract__office_id)">{{ formatThisNumber(DebitNote__amount_converted, '0,0') }}</td>
                        <td class="px-0 text-right" v-if="taxMode && [1,3].includes(Contract__office_id)">{{ formatThisNumber(DebitNote__amount_converted, '0,0.00') }}</td>
                        <td class="px-0 text-right">{{ formatThisNumber(DebitNote__amount, currencyFormatPrecision) }}</td>
                    </tr>
                    <tr class="table-row" v-if="Contract__commission_term_id == 3">
                        <td class="px-0 d-flex flex-row">
                            <span class="width-25-pct">{{ $t('message.feePerUnit') }}:</span>
                            <span>{{ formatThisNumber(Contract__commission_unit_price,'0,0.00') }}</span>
                        </td>
                        <td class="px-0 text-right" v-if="taxMode && [21].includes(Contract__office_id)">{{ formatThisNumber(DebitNote__amount_converted, '0,0') }}</td>
                        <td class="px-0 text-right" v-if="taxMode && [1,3].includes(Contract__office_id)">{{ formatThisNumber(DebitNote__amount_converted, '0,0.00') }}</td>
                        <td class="px-0 text-right">{{ formatThisNumber(DebitNote__amount, currencyFormatPrecision) }}</td>
                    </tr>
                </template>
            </v-simple-table>
        </v-col>
        <div class="px-0 pb-0 pt-3 ma-0 mt-auto">
            <v-simple-table
                class="specification-table"
                dense
            >
                <template v-slot:default>
                    <tr v-if="DebitNote__remarks != null || Contract__fsc != null">
                        <td :colspan="taxMode ? 3 : 2">
                            <div class="d-flex flex-column">
                                <span class="font-weight-bold py-0 my-0">{{ $t('message.remarks') }}:</span>
                                <span style="white-space: pre-line" v-if="DebitNote__remarks != null">
                                    {{ DebitNote__remarks != null ? DebitNote__remarks.trim() : '' }}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="Contract__fsc != null">
                        <td :colspan="taxMode ? 3 : 2">
                            <v-row no-gutters v-if="[3,4,6,2,5].includes(Contract__fsc)">
                                <v-col class="d-flex flex-column col-12">
                                    <span>{{ $t('message.certCode') + ' : ' + getCertification(Contract__fsc,'cert_no') }}</span>
                                    <span>{{ $t('message.expiryDate') + ' : ' + getCertification(Contract__fsc,'cert_expiry_date') }}</span>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                    <template v-if="[21].includes(Contract__office_id)">
                        <tr>
                            <td class="pa-0 font-weight-bold border-top-1 pa-0 font-weight-bold text-right">{{ $t('message.totalDue') }}</td>
                            <td class="pa-0 font-weight-bold border-top-1 pa-0 font-weight-bold width-15-pct text-right" v-if="taxMode">
                                {{ formatThisNumber(DebitNote__total_less_tax_converted, '0,0') }}
                            </td>
                            <td class="pa-0 font-weight-bold border-top-1 pa-0 font-weight-bold width-15-pct text-right">
                                {{ formatThisNumber(DebitNote__total_less_tax, currencyFormatPrecision) }}
                            </td>
                        </tr>
                        <tr>
                            <td class="pa-0 font-weight-bold pa-0 font-weight-bold text-right">{{ countryTaxTerm }}</td>
                            <td class="pa-0 font-weight-bold pa-0 font-weight-bold width-15-pct text-right" v-if="taxMode">
                                {{ formatThisNumber(DebitNote__tax_converted, '0,0') }}
                            </td>
                            <td class="pa-0 font-weight-bold pa-0 font-weight-bold width-15-pct text-right">
                                {{ formatThisNumber(DebitNote__tax, currencyFormatPrecision) }}
                            </td>
                        </tr>
                        <tr>
                            <td class="pa-0 font-weight-bold border-bottom-1 pa-0 font-weight-bold text-right">{{ $t('message.grandTotal') }}</td>
                            <td class="pa-0 font-weight-bold border-bottom-1 pa-0 font-weight-bold width-15-pct text-right" v-if="taxMode">
                                {{ formatThisNumber(DebitNote__total_converted, '0,0') }}
                            </td>
                            <td class="pa-0 font-weight-bold border-bottom-1 pa-0 font-weight-bold width-15-pct text-right">
                                {{ formatThisNumber(DebitNote__total, currencyFormatPrecision) }}
                            </td>
                        </tr>
                    </template>
                    <template v-if="[1,3].includes(Contract__office_id)">
                        <tr>
                            <td class="pa-0 font-weight-bold border-top-1 border-bottom-1 pa-0 font-weight-bold text-right">{{ $t('message.total') }}</td>
                            <td class="pa-0 font-weight-bold border-top-1 border-bottom-1 pa-0 font-weight-bold width-15-pct text-right" v-if="taxMode">
                                {{ formatThisNumber(DebitNote__total_converted, '0,0.00') }}
                            </td>
                            <td class="pa-0 font-weight-bold border-top-1 border-bottom-1 pa-0 font-weight-bold width-15-pct text-right">
                                {{ formatThisNumber(DebitNote__total, currencyFormatPrecision) }}
                            </td>
                        </tr>
                    </template>
                </template>
            </v-simple-table>
            <v-row no-gutters class="pt-2">
                <v-col cols="2" class="font-weight-bold">{{ $t('message.pleaseRemitTo') }}:</v-col>
                <v-col cols="10">
                    <v-row no-gutters v-if="Bank__beneficiary != ''">
                        <v-col class="col-2-5">{{ $t('message.beneficiaryName') }}:</v-col>
                        <v-col class="col-9-5">{{ Bank__beneficiary }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__account != ''">
                        <v-col class="col-2-5">{{ $t('message.account') }}:</v-col>
                        <v-col class="col-9-5">{{ Bank__account }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__title != ''">
                        <v-col class="col-2-5">{{ $t('message.bank') }}:</v-col>
                        <v-col class="col-9-5">{{ Bank__title }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__swiftcode != ''">
                        <v-col class="col-2-5">{{ $t('message.swiftId') }}:</v-col>
                        <v-col class="col-9-5">{{ Bank__swiftcode }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__address != ''">
                        <v-col class="col-2-5">{{ $t('message.bankAddress') }}:</v-col>
                        <v-col class="col-9-5">{{ Bank__address + (Bank__country != null ? ', ' + Bank__country : '') }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__agent != ''">
                        <v-col class="col-2-5">{{ $t('message.agentBank') }}:</v-col>
                        <v-col class="col-9-5">{{ Bank__agent }}</v-col>
                    </v-row>
                    <v-row no-gutters v-if="Bank__agentswiftcode != ''">
                        <v-col class="col-2-5">{{ $t('message.swiftId') }}:</v-col>
                        <v-col class="col-9-5">{{ Bank__agentswiftcode }}</v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12" class="text-center font-weight-bold">{{ $t('message.documentElectronicallyGenerated') }}</v-col>
            </v-row>
        </div>
        <h1 id="loaded" v-if="loaded" style="margin-bottom: 0px !important;"></h1>
    </div>
</template>

<script>
import {formatDate, log, numberFormat} from "Helpers/helpers";
import AppConfig from "Constants/AppConfig";
import {api} from "Api/index";
import {
    // certificationsNew,
    containerSizes,
    // currencies,
    offices
} from "../../../store/modules/appic/constants"

export default {
    name: "PrintDebitNote",
    props: ['debitNoteId'],
    data () {
        return {
            appLogo: AppConfig.appSessionLogo,
            documentTitle: null,
            loaded: false,
            certificationsNew: [],
            // certificationsNew: certificationsNew,
            containerSizes: containerSizes,
            currencies: [],
            // currencies: currencies,
            offices: offices,
            Bank__address: null,
            Bank__agent: null,
            Bank__agentswiftcode: null,
            Bank__country: null,
            Bank__id: null,
            Bank__title: null,
            Bank__account: null,
            Bank__swiftcode: null,
            Bank__beneficiary: null,
            Contact__email: null,
            Contact__hp: null,
            Contact__name: null,
            Contact__prefix: null,
            Contact__tel: null,
            Contract__commission_term_id: 0,
            Contract__commission_term_against_id: 0,
            Contract__commission_unit_price: 0,
            Contract__currency_id: 1,
            Contract__etddate: null,
            Contract__fsc: null,
            Contract__office_id: null,
            Contract__partial_no: null,
            Contract__revision_no: null,
            Contract__title: null,
            Contract__version: null,
            Customer__title: null,
            Customer__address1: null,
            Customer__address2: null,
            Customer__address3: null,
            Customer__city: null,
            Customer__country: null,
            Customer__country_id: null,
            Customer__gstno: null,
            Customer__postcode: null,
            Customer__state: null,
            Customer__taxno_type: null,
            Customer__uscc: null,
            DebitNote__amount: 0,
            DebitNote__amount_converted: 0,
            DebitNote__containercount: 0,
            DebitNote__containersize_id: 0,
            DebitNote__cfrciffee: 0,
            DebitNote__dndate: null,
            DebitNote__dnvalue: 0,
            DebitNote__fobfee: 0,
            DebitNote__freight: 0,
            DebitNote__fsc: 0,
            DebitNote__gstpercent: 0,
            DebitNote__gstrmrate: 0,
            DebitNote__invno: null,
            DebitNote__mbffee: 0,
            DebitNote__mbfqty: 0,
            DebitNote__remarks: null,
            DebitNote__total_less_tax: 0,
            DebitNote__total_less_tax_converted: 0,
            DebitNote__title: null,
            DebitNote__tax: 0,
            DebitNote__tax_converted: 0,
            DebitNote__total: 0,
            DebitNote__total_converted: 0,
            DebitNote__valuetype: 0,
            Office__title: null,
            Office__address1: null,
            Office__address2: null,
            Office__city: null,
            Office__companyno: null,
            Office__contracttel: null,
            Office__country: null,
            Office__invoiceemail: null,
            Office__invoicetel: null,
            Office__postcode: null,
            Office__state: null,
            Office__stamp: null,
            Office__stamp_ws: null,
            Office__website: null,
            Supplier__title: null,
            Supplier__address1: null,
            Supplier__address2: null,
            Supplier__address3: null,
            Supplier__city: null,
            Supplier__country: null,
            Supplier__country_id: null,
            Supplier__gstno: null,
            Supplier__postcode: null,
            Supplier__state: null,
            Supplier__taxno_type: null,
            SupplierContact__email: null,
            SupplierContact__hp: null,
            SupplierContact__name: null,
            SupplierContact__prefix: null,
            SupplierContact__tel: null,
            valueTypes: [
                {value: 0, text: this.$t('message.cif')},
                {value: 1, text: this.$t('message.cfr')},
                {value: 2, text: this.$t('message.fob')}
            ],
            taxMode: false
        }
    },
    computed: {
        currency () {
            if(this.DebitNote__currency_id == null) {
                return this.currencies.find((currency) => currency.Currency.id === this.Contract__currency_id)?.Currency?.code
            } else {
                return this.currencies.find((currency) => currency.Currency.id === this.DebitNote__currency_id)?.Currency?.code
            }
        },
        currencyFormatPrecision () {
            if(this.DebitNote__currency_id == null) {
                return this.currencies.find((currency) => currency.Currency.id === this.Contract__currency_id)?.Currency?.formatPrecision
            } else {
                return this.currencies.find((currency) => currency.Currency.id === this.DebitNote__currency_id)?.Currency?.formatPrecision
            }
        },
        officeCurrency () {
            let office = this.offices.find(office => office.Office.id == this.Contract__office_id)
            if(office){
                let currency = this.currencies.find(currency => currency.Currency.id == office.Office.currency_id)
                if(currency) {
                    return currency.Currency.code
                } else {
                    return 'RM'
                }
            }
            return 'RM'
        },
        countryTaxTerm () {
            let office = this.offices.find(office => office.Office.id == this.Contract__office_id)
            if(office){
                let rate = office.Office.taxrate
                if(this.DebitNote__gstpercent != null){
                    rate = this.DebitNote__gstpercent
                }
                return office.Office.taxterm + " " + (rate.toString()) + "%"
            }
            return ''
        }
    },
    methods: {
        formatDate,
        formatThisNumber(value, format) {
            return numberFormat(value, format)
        },
        getCertification(certificationId, certificationInfo) {
            let certificationObj = this.certificationsNew.find(c => c.Certification.id == certificationId)
            if(certificationObj){
                return certificationObj?.Certification[certificationInfo]
            }
            return ''
        },
        getCertifications() {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/certifications",{
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getCurrencies() {
            return new Promise((resolve, reject) => {
                log('get currencies')
                api
                    .get("/print/currencies",{
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        // getTaxId () {
        //     let taxId = ''
        //     if(this.Customer__gstno != null && this.Customer__taxno_type != null) taxId = this.Customer__taxno_type + ': ' + this.Customer__gstno
        //     if(this.Customer__gstno != null && this.Customer__taxno_type == null) taxId = this.Customer__gstno
        //     return taxId
        // },
        getTaxId () {
            let taxId = ''
            if(this.Contract__commission_term_against_id == 1) {
                if (this.Customer__gstno != null && this.Customer__taxno_type != null) taxId = this.Customer__taxno_type + ': ' + this.Customer__gstno
                if (this.Customer__gstno != null && this.Customer__taxno_type == null) taxId = this.Customer__gstno
            } else if(this.Contract__commission_term_against_id == 0){
                if (this.Supplier__gstno != null && this.Supplier__taxno_type != null) taxId = this.Supplier__taxno_type + ': ' + this.Supplier__gstno
                if (this.Supplier__gstno != null && this.Supplier__taxno_type == null) taxId = this.Supplier__gstno
            }
            return taxId
        },
        loadDebitNoteById ( debitNoteId ) {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/debitnotes/" + debitNoteId,{
                    })
                    .then(response => {
                        for(let key in response.data.data[0]){
                            this[key] = response.data.data[0][key]
                        }
                        this.DebitNote__amount_converted = parseFloat(this.DebitNote__amount) * parseFloat(this.DebitNote__gstrmrate)

                        this.DebitNote__total = parseFloat(this.DebitNote__amount)
                        this.DebitNote__total_converted = this.DebitNote__total * parseFloat(this.DebitNote__gstrmrate)

                        //calculate tax
                        if([21].includes(this.Contract__office_id)){
                            let office = this.offices.find(office => office.Office.id == this.Contract__office_id)
                            if(office){
                                // let taxRate = 1 / office.Office.taxrate
                                const taxRate = office.Office.taxrate / 100

                                this.DebitNote__total_less_tax = this.DebitNote__total / (1 + taxRate)
                                this.DebitNote__total_less_tax_converted = this.DebitNote__total_converted / (1 + taxRate)
                                this.DebitNote__tax = this.DebitNote__total - this.DebitNote__total_less_tax
                                this.DebitNote__tax_converted = this.DebitNote__total_converted - this.DebitNote__total_less_tax_converted

                                // this.DebitNote__tax = this.DebitNote__total * taxRate
                                // this.DebitNote__tax_converted = this.DebitNote__total_converted * taxRate

                                // this.DebitNote__total_less_tax = this.DebitNote__total - this.DebitNote__tax
                                // this.DebitNote__total_less_tax_converted = this.DebitNote__total_converted - this.DebitNote__tax_converted
                            }
                        }

                        if(this.DebitNote__remarks == null || this.DebitNote__remarks == ''){
                            this.DebitNote__remarks = this.$t('message.pleasePayInUSD')
                        }
                        resolve( debitNoteId )
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        }
    },
    created () {
        this.getCertifications()
            .then((certifications) => {
                this.certificationsNew = certifications
                this.getCurrencies()
                    .then((currencies) => {
                        this.currencies = currencies
                    })
                    .catch()
            })
            .catch()
        if (this.$route.matched.some(({name}) => name === 'print_debitnote') || this.$route.matched.some(({name}) => name === 'print_tax_debitnote')) {
            if(this.$route.matched.some(({name}) => name === 'print_tax_debitnote')) this.taxMode = true
            this.loadDebitNoteById(this.debitNoteId)
                .then(()=>{
                    this.loaded = true
                })
        }
    }
}
</script>

<style>
@font-face {
    font-family: 'AppicArial';
    src: url('/static/fonts/Arial.woff2') format('woff2'),
    url('/static/fonts/Arial.woff2') format('woff'),
    url('/static/fonts/Arial.woff2') format('truetype');
}
.border-top-1 {
    border-top: 1px solid black !important;
}
.border-bottom-1 {
    border-bottom: 1px solid black !important;
}
.border-top-2 {
    border-top: 2px solid black;
}
.border-bottom-2 {
    border-bottom: 2px solid black !important;
}
.col-2-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
}
.col-9-5 {
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
}
.document-caption {
    font-size: 16px !important;
    line-height: 18px;
}
.footer-note {
    font-size: 8pt !important;
}
.header-title {
    font-size: 20pt !important;
    line-height: 25px;
    font-weight: bold !important;
}
.header-subtitle {
    font-size: 10.5pt !important;
}
.specification-table table tr td {
    font-family:'AppicArial' !important;
    line-height:15px !important;
    font-size:9pt !important;
    padding:2px !important;
}
.specification-table table tr.table-header td {
    font-family:'AppicArial' !important;
    line-height:15px !important;
    font-size:9pt !important;
    padding:0px !important;
    height: 15px !important;
}
.specification-table table tr.table-row-main td {
    font-family:'AppicArial' !important;
    line-height:20px !important;
    font-size:9pt !important;
    padding:0px !important;
    height: 20px !important;
}
.specification-table table tr.table-row-sub td {
    font-family:'AppicArial' !important;
    line-height:15px !important;
    font-size:9pt !important;
    padding:0px !important;
    height: 15px !important;
}
.stamp-area {
    height: 100px;
}
.v-application{
    font-family:'AppicArial' !important;
    line-height:15px !important;
    font-size:9pt !important;
    padding:2px !important;
}
.v-application{
    font-family: 'AppicArial' !important;
    line-height:15px !important;
    font-size:9pt !important;
    padding:2px !important;
}
body {
    width: 18.5cm !important;
    margin: 0 !important;
}
* {
    text-rendering: geometricprecision !important;
}
</style>